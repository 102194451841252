.form-input {
    margin-top: 400px;
}
.join-name {
    width: 400px;
}

.join-button {
    margin-left: 5px;
    line-height: 50px;
}