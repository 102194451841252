.layout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: black;
}
.menu, .feed, .users {
    margin: 5px;
    border: 1px darkgrey solid;
    border-radius: 5px;
    flex-grow: 1;
    background-color: white;
}

button.newmsg-btn {
    height: 56px;
    margin-left: 5px;
}

.messages {
    height: calc(100% - 100px);
    overflow-y: auto;
}

.tabs-content-wrapper {
    height: calc(100% - 60px);
    overflow-y: auto;
}

.newMsgForm {
    margin: 20px;
}